///////////////////////////////////////////////////////////////////////////////
// Copyright (C) 2002-2021, Open Design Alliance (the "Alliance").
// All rights reserved.
//
// This software and its documentation and related materials are owned by
// the Alliance. The software may only be incorporated into application
// programs owned by members of the Alliance, subject to a signed
// Membership Agreement and Supplemental Software License Agreement with the
// Alliance. The structure and organization of this software are the valuable
// trade secrets of the Alliance and its suppliers. The software is also
// protected by copyright law and international treaty provisions. Application
// programs incorporating this software must include the following statement
// with their copyright notices:
//
//   This application incorporates Open Design Alliance software pursuant to a
//   license agreement with Open Design Alliance.
//   Open Design Alliance Copyright (C) 2002-2021 by Open Design Alliance.
//   All rights reserved.
//
// By use of this software, its documentation or related materials, you
// acknowledge and accept the above terms.
///////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const feedbacks = {
  ChunkLoadError: {
    title: "Error loading page",
    subTitle: "There seems to be a problem with your internet connection.",
    button: "Reload",
    fallbackUrl: 0,
  },
  _default: {
    title: "Oops!",
    subTitle: "Something went wrong. The page encountered an error and has been closed.",
    button: "Back to Home",
    fallbackUrl: "/",
  },
};

function ErrorStub({ error, onResetError }) {
  const navigate = useNavigate();
  const feedback = feedbacks[error.name] || feedbacks._default;
  return (
    <Result
      status="error"
      icon={<CloseCircleOutlined />}
      title={feedback.title}
      subTitle={feedback.subTitle}
      extra={
        <Button
          type="primary"
          onClick={() => {
            onResetError();
            navigate(feedback.fallbackUrl);
          }}
        >
          {feedback.button}
        </Button>
      }
    />
  );
}

export default ErrorStub;
