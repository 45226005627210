///////////////////////////////////////////////////////////////////////////////
// Copyright (C) 2002-2021, Open Design Alliance (the "Alliance").
// All rights reserved.
//
// This software and its documentation and related materials are owned by
// the Alliance. The software may only be incorporated into application
// programs owned by members of the Alliance, subject to a signed
// Membership Agreement and Supplemental Software License Agreement with the
// Alliance. The structure and organization of this software are the valuable
// trade secrets of the Alliance and its suppliers. The software is also
// protected by copyright law and international treaty provisions. Application
// programs incorporating this software must include the following statement
// with their copyright notices:
//
//   This application incorporates Open Design Alliance software pursuant to a
//   license agreement with Open Design Alliance.
//   Open Design Alliance Copyright (C) 2002-2021 by Open Design Alliance.
//   All rights reserved.
//
// By use of this software, its documentation or related materials, you
// acknowledge and accept the above terms.
///////////////////////////////////////////////////////////////////////////////

import React, { useState } from "react";
import { Image, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
}

const Dropzone = React.forwardRef(({ customRequest, onPreview, disabled, icon, text, hint, ...rest }, ref) => {
  const [previewUrl, setPreviewUrl] = useState(undefined);

  function defaultRequest(options) {
    options.onSuccess({}, {});
  }

  async function defaultPreview(file) {
    if (file.thumbUrl) {
      await readFileAsDataURL(file.originFileObj)
        .then((url) => setPreviewUrl(url))
        .catch((e) => console.error("Cannot read file.", e));
    }
  }

  return (
    <Upload
      ref={ref}
      {...rest}
      type="drag"
      disabled={disabled || !!previewUrl}
      customRequest={customRequest || defaultRequest}
      onPreview={onPreview || defaultPreview}
    >
      {icon !== null && <p className="ant-upload-drag-icon">{icon || <CloudUploadOutlined />}</p>}
      {text && <p className="ant-upload-text">{text}</p>}
      {hint && <p className="ant-upload-hint">{hint}</p>}
      <Image
        wrapperStyle={{ display: "block" }}
        style={{ display: "none" }}
        preview={{
          visible: !!previewUrl,
          src: previewUrl,
          mask: "",
          onVisibleChange: (visible) => {
            if (!visible) setPreviewUrl(undefined);
          },
        }}
      />
    </Upload>
  );
});

Dropzone.displayName = "Dropzone";
Dropzone.readFileAsDataURL = readFileAsDataURL;

export default Dropzone;
