///////////////////////////////////////////////////////////////////////////////
// Copyright (C) 2002-2021, Open Design Alliance (the "Alliance").
// All rights reserved.
//
// This software and its documentation and related materials are owned by
// the Alliance. The software may only be incorporated into application
// programs owned by members of the Alliance, subject to a signed
// Membership Agreement and Supplemental Software License Agreement with the
// Alliance. The structure and organization of this software are the valuable
// trade secrets of the Alliance and its suppliers. The software is also
// protected by copyright law and international treaty provisions. Application
// programs incorporating this software must include the following statement
// with their copyright notices:
//
//   This application incorporates Open Design Alliance software pursuant to a
//   license agreement with Open Design Alliance.
//   Open Design Alliance Copyright (C) 2002-2021 by Open Design Alliance.
//   All rights reserved.
//
// By use of this software, its documentation or related materials, you
// acknowledge and accept the above terms.
///////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import { ClockCircleOutlined, FileOutlined, GoldOutlined, ProjectOutlined } from "@ant-design/icons";

function SidebarTopMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const items = [
    { key: "/files", label: t("Files"), icon: <FileOutlined /> },
    { key: "/assemblies", label: t("Assemblies"), icon: <GoldOutlined /> },
    { key: "/jobs", label: t("Jobs"), icon: <ClockCircleOutlined /> },
    { key: "/projects", label: t("Projects"), icon: <ProjectOutlined /> },
  ];
  return (
    <Menu
      className={className}
      selectedKeys={[location.pathname]}
      mode="inline"
      items={items}
      onClick={(item) => navigate(item.key)}
    />
  );
}

export default SidebarTopMenu;
